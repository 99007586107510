.wrapper-contact-me {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    transition: 0.3s;
    background-color: rgb(33, 33, 33);
}

.wrapper-black-contact-me {
    opacity: 0;
    transition: 0.3s;
}

.contact-form-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    height: 60vh;
    margin-top: 10vh;
    margin-bottom: 20vh;
}

.contact-me-title {
    position: absolute;
    float: right;
    top: 0px;
    left: 40px;
    height: auto;
    font-weight: 400;
    font-size: 23px;
    color: rgb(143, 143, 143);
}

.contact-form-user-info {
    width: 80vw;
    max-height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
}

.contact-form-user-input-section {
    text-align: center;
}

.contact-form-user-input-section-title {
    color: grey;
    transition: 0.3s;
}

.contact-form-user-first-name, .contact-form-user-last-name, .contact-form-user-email, .contact-form-user-message {
    background-color: rgb(33, 33, 33);
    border: 1px solid grey;
    width: min(18vw, 25vw);
    color: grey;
    margin-bottom: 10px;
}

.contact-form-user-email {
    width: min(25vw, 25vw);
}

.contact-form-user-message {
    height: 40vh;
    width: 80vw;
    resize: none;
}

.contact-me-user-info-picture {
    width: 35vw;
    height: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-me-profile-picture {
    height: 90%;
    border: 1px solid black;
    padding: 3px;
    margin-left: 5px;
    margin-right: 5px;
}

@media screen and (max-width: 500px) {
    .contact-form-user-input-section-title {
        font-size: 12px;
        transition: 0.3s;
    }
}