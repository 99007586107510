.wrapper-projects {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    opacity: 1;
    transition: 0.3s;
    background-color: rgb(33, 33, 33);
}

.wrapper-black-projects {
    opacity: 0;
    transition: 0.3s;
}

.projects-window-wrapper {
    min-width: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80vw;
    height: 60vh;
    margin-top: 10vh;
    margin-bottom: 20vh;
    border: 1px solid grey;
}

.projects-title {
    position: absolute;
    float: right;
    top: 0px;
    left: 40px;
    height: auto;
    font-weight: 400;
    font-size: 23px;
    color: rgb(143, 143, 143);
}

.projects-window-top-bar {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid grey;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

.projects-window-top-bar-icon {
    /* margin: 1px; */
    height: 70%;
    margin-right: 3px;
    /* float: right; */
}

.projects-window-navigation-bar {
    width: 100%;
    height: 20px;
    border-bottom: 1px solid grey;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.projects-window-navigation-bar-icon {
    height: 70%;
    margin-left: 15px;
    cursor: pointer;
}

.backButtonSVG:hover {
    /* stroke: blueviolet; */
    fill: blueviolet;
}

.projects-window-file-system-wrapper {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}

.projects-window-file-system-sidebar {
    min-width: 100px;
    width: 20%;
    height: 100%;
    border-right: 1px solid grey;
    display: flex;
    flex-flow: column wrap;
    /* justify-content: center; */
    align-items: flex-start;
}

.projects-window-file-system-sidebar-icon {
    /* margin-top: 10px; */
    /* margin-top: 10px; */
    height: 30px;
    margin-right: 10px;
    animation: 0.3s ease-out 0s 1 opacity-animation;
}
.projects-window-file-system-sidebar-icon-folder {
    height: 15px;
    margin-right: 10px;
    animation: 0.3s ease-out 0s 1 opacity-animation;
}

.root {
    display: flex;
    flex-direction: row;
    margin-left: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: 0.3s ease-out 0s 1 opacity-animation;
}

.folder {
    display: flex;
    flex-direction: row;
    margin-left: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: 0.3s ease-out 0s 1 opacity-animation;
}

.folder-level-1 {
    display: flex;
    flex-direction: row;
    margin-left: 40px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: 0.3s ease-out 0s 1 opacity-animation;
}
.folder-level-2 {
    display: flex;
    flex-direction: row;
    margin-left: 60px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    animation: 0.3s ease-out 0s 1 opacity-animation;
}

@keyframes opacity-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
.projects-window-file-system-sidebar-folder-name {
    color: grey;
    text-decoration: none;
    animation: 0.3s ease-out 0s 1 opacity-animation;
}


.projects-window-file-system-files {
    min-width: 100px;
    width: 80%;
    height: 100%;
    display: flex;
    flex-flow: row wrap;
    /* flex-direction: row; */
    /* flex-wrap: wrap; */
    justify-content: flex-start;
    align-items: flex-start;
    padding: 10px;
}

.projects-window-file-system-files-item-wrapper {
    height: fit-content;
    width: 90px;
    padding: 10px;
    transition: 0.3s;
    cursor: pointer;
    animation: 0.3s ease-out 0s 1 opacity-animation;
}

.projects-window-file-system-files-item-wrapper:hover {
    background-color: rgba(70, 70, 70, 0.400);
}

.projects-window-file-system-files-item-image {
    width: 90px;
    height: 60px;
    transition: 1s;
}

.projects-window-file-system-files-item-title {
    color: grey;
    text-align: center;
    transition: 1s;
}

.contact-form-user-info {
    width: 80vw;
    height: 20vh;
    display: flex;
    align-items: center;
    justify-content: space-around;
    /* border: 1px solid white; */
}

.contact-form-user-input-section {
    /* border: 1px solid white; */
    text-align: center;
}

.contact-form-user-input-section-title {
    color: grey;
}

.contact-form-user-first-name, .contact-form-user-last-name, .contact-form-user-email, .contact-form-user-message {
    background-color: rgb(33, 33, 33);
    border: 1px solid grey;
    width: min(18vw, 25vw);
    color: grey;
}

.contact-form-user-email {
    width: min(25vw, 25vw);
}

.contact-form-user-message {
    /* background-color: rgb(33, 33, 33); */
    /* border: 1px solid grey; */
    height: 40vh;
    width: 80vw;
    /* text-align: initial; */
    /* display: flex; */
    /* align-items: ; */
}

.profile-picture-projects {
    display: none;
}